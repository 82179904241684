import React, { useRef } from "react"
import { Helmet } from "react-helmet"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRight,
  faPhoneAlt,
  faEnvelope,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons"
import CarouselMain from "../components/CarouselMain"
import Heading from "../components/Heading"
import Layout from "./../components/Layout"

import { Marker } from "react-leaflet"
import Map from "./../components/Map"

import ConceptsSlide from "../components/ConceptsSlide"
import {
  imgContainer,
  img,
  imgAbout01,
  imgAbout02,
  product,
  contact,
  location,
  locationDetails,
  locationMap,
  bankIcon,
} from "./../styles/home.module.scss"
import Cards from "../components/Cards"
import { graphql, Link } from "gatsby"
import { motion } from "framer-motion"
import Img from "gatsby-image"

const LOCATION = {
  lat: -6.2793,
  lng: 107.04754,
}
const CENTER = [LOCATION.lat, LOCATION.lng]
const DEFAULT_ZOOM = 15
// const ZOOM = 10

// const timeToZoom = 2000
// const timeToOpenPopupAfterZoom = 4000
// const timeToUpdatePopupAfterZoom = timeToOpenPopupAfterZoom + 3000

// const popupContentHello = `<p>Hello 👋</p>`

// const MapEffect = ({ markerRef }) => {
//   const map = useMap()

//   useEffect(() => {
//     if (!markerRef.current || !map) return
//     ;(async function run() {
//       const popup = L.popup({
//         maxWidth: 800,
//       })

//       const location = await getCurrentLocation().catch(() => LOCATION)

//       const { current: marker } = markerRef || {}

//       marker.setLatLng(location)
//       popup.setLatLng(location)
//       popup.setContent(popupContentHello)

//       setTimeout(async () => {
//         await promiseToFlyTo(map, {
//           zoom: ZOOM,
//           center: location,
//         })

//         marker.bindPopup(popup)

//         setTimeout(() => marker.openPopup(), timeToOpenPopupAfterZoom)
//       }, timeToZoom)
//     })()
//   }, [map, markerRef])

//   return null
// }

export default function Home({ data }) {
  const homeIntro = data.allHomeJson.nodes[0].home.desc
  const homeImg01 = data.allHomeJson.nodes[0].home.img01
  const homeImgLink01 = data.allHomeJson.nodes[0].home.link01
  const homeImg02 = data.allHomeJson.nodes[0].home.img02
  const homeImgLink02 = data.allHomeJson.nodes[0].home.link02
  const productIntro = data.allHomeJson.nodes[0].products.desc
  const productImg01 = data.allHomeJson.nodes[0].products.img01
  const productImg02 = data.allHomeJson.nodes[0].products.img02
  const productImgLink01 = data.allHomeJson.nodes[0].products.link01
  const productImgLink02 = data.allHomeJson.nodes[0].products.link02
  const products = data.allHomeJson.nodes[0].products.items

  const banner01 = data.allHomeJson.nodes[0].banner01.childImageSharp.fluid
  const banner02 = data.allHomeJson.nodes[0].banner02.childImageSharp.fluid

  const banks = data.allHomeJson.nodes[0].bank
  const videos = data.allHomeJson.nodes[0].videos

  const markerRef = useRef()

  const mapSettings = {
    center: CENTER,
    defaultBaseMap: "OpenStreetMap",
    zoom: DEFAULT_ZOOM,
  }

  console.log(videos)

  return (
    <Layout>
      <Helmet>
        <title>
          Grand Wisata Bekasi | Kota Mandiri Terintegrasi dan Terencana
        </title>
        <meta
          name="description"
          content="Grand Wisata Bekasi merupakan kawasan pemukiman modern yang dikembangkan oleh Sinarmas Land, sebuah perusahaan properti nasional terkemuka di Asia Tenggara dengan pengalaman lebih dari 40 tahun di industri property Indonesia."
        />
        <meta
          name="keywords"
          content="grand wisata bekasi, grand wisata property, grand wisata, grand wisata tambun, perumahan grand wisata"
        />
        <meta name="distribution" content="web" />
        <link rel="canonical" href="https://www.grandwisataproperty.com" />
        <meta
          property="og:title"
          content="Grand Wisata Bekasi | Kota Mandiri Terintegrasi dan Terencana di Timur Jakarta"
        />
        <meta
          property="og:description"
          content="Grand Wisata Bekasi merupakan kawasan pemukiman modern yang dikembangkan oleh Sinarmas Land, sebuah perusahaan properti nasional terkemuka di Asia Tenggara dengan pengalaman lebih dari 40 tahun di industri property Indonesia."
        />
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <CarouselMain />
        <div className="container">
          <Img
            fluid={banner01}
            className="banner-ad"
            alt="Mall Living World Grand Wisata Bekasi"
          ></Img>
        </div>

        <section className={`container`}>
          <div className="row">
            <div className="col-md-6">
              <Heading>Grand Wisata Bekasi</Heading>
              <p>{homeIntro}</p>
              <Link className="button-link" to="/about">
                Lebih lanjut <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </div>
            <div className="col-md-6">
              <div className={imgContainer}>
                <a href={homeImgLink01}>
                  <Img
                    className={`${img} ${imgAbout01}`}
                    fluid={homeImg01.childImageSharp.fluid}
                    alt="Mall Living World Grand Wisata Bekasi"
                  ></Img>
                </a>
                <a href={homeImgLink02}>
                  <Img
                    className={`${img} ${imgAbout02}`}
                    fluid={homeImg02.childImageSharp.fluid}
                    alt="O8 Perfect Home"
                  ></Img>
                </a>
              </div>
            </div>
          </div>
          <ConceptsSlide />
        </section>
        <Img fluid={banner02} className="banner" alt="Section Banner"></Img>
        <section className={`container ${product}`}>
          <div className="row">
            <div className="col-md-6">
              <Heading>Produk</Heading>
              <p>{productIntro}</p>
            </div>
            <div className="col-md-6 mb-5 mb-md-0">
              <div className={imgContainer}>
                <Link to={productImgLink01}>
                  <Img
                    className={`${img} ${imgAbout01}`}
                    fluid={productImg01.childImageSharp.fluid}
                    alt="O8 Perfect Home"
                  />
                </Link>
                <Link to={productImgLink02}>
                  <Img
                    className={`${img} ${imgAbout02}`}
                    fluid={productImg02.childImageSharp.fluid}
                    alt="Garden Hous"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12">
              <h2>Produk Terkini</h2>
              <Cards datas={products} />
              <br />
              <Link className={`button`} to="/products">
                Lebih banyak
              </Link>
            </div>
          </div>
          {videos !== "dummy" ? (
            <div className="row mt-5">
              <div className="col-12">
                <h2>Video Terkini</h2>
                <div className="row mt-4">
                  {videos.map(item => (
                    <div className="col-md-4">
                      <iframe
                        width="100%"
                        src={item}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </section>

        <section className="container text-center pt-0">
          <p>KPR partnership:</p>
          {banks.map(item => (
            <img
              src={item.childImageSharp.fluid.src}
              className={`${bankIcon}`}
              alt={bankIcon}
            ></img>
          ))}
        </section>

        <section className={`container ${contact}`}>
          <div className={`row ${location}`}>
            <div className={`col-md-6 ${locationDetails}`}>
              <h2>Lokasi</h2>
              <hr />
              <p>
                Grand Wisata Bekasi, <br />
                Jl. Celebration Boulevard Kav 1, <br />
                Grand Wisata Bekasi, 17510
              </p>
              <div className="tel">
                <FontAwesomeIcon icon={faPhoneAlt} />
                +62 87888 570 800
              </div>
              <div className="mail">
                <FontAwesomeIcon icon={faEnvelope} />
                dhona.grandwisata@gmail.com
              </div>
            </div>
            <div className={`col-md-6 ${locationMap}`}>
              <Map {...mapSettings}>
                {/* <MapEffect markerRef={markerRef} /> */}
                <Marker ref={markerRef} position={CENTER} />
              </Map>
            </div>
          </div>
        </section>
      </motion.div>
    </Layout>
  )
}

export const query = graphql`
  {
    allHomeJson {
      nodes {
        videos
        bank {
          childImageSharp {
            fluid {
              src
            }
          }
        }
        home {
          desc
          img01 {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          img02 {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          link01
          link02
        }
        banner01 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        banner02 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        products {
          desc
          items {
            img {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            link
            product
            type
          }
          img01 {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          img02 {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          link01
          link02
        }
      }
    }
  }
`
