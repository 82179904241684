// extracted by mini-css-extract-plugin
export var imgContainer = "home-module--img-container--1A3XE";
export var img = "home-module--img--3LWGK";
export var imgAbout01 = "home-module--img-about-01---jOi7";
export var imgAbout02 = "home-module--img-about-02--1w5x4";
export var product = "home-module--product--3VdkO";
export var productButton = "home-module--product-button--jREJA";
export var contact = "home-module--contact--3xEBF";
export var location = "home-module--location--2Csqh";
export var locationDetails = "home-module--location-details--1aFQl";
export var locationMap = "home-module--location-map--ekF7C";
export var bankIcon = "home-module--bank-icon--3GOxd";