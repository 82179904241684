import React from "react"
import Card from "../Card"
import "./cards.scss"

function Cards({ datas }) {
  return (
    <div className="row cards mt-4 mb-4">
      {datas.map(data => (
        <Card data={data} key={data.product} />
      ))}
    </div>
  )
}

export default Cards
