import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import conceptLive from "./../../images/concept/concept-live.jpg"
import conceptPlay from "./../../images/concept/concept-play.jpg"
import conceptWork from "./../../images/concept/concept-work.jpg"
import "./conceptslide.scss"
import { AnimatePresence, motion } from "framer-motion"

function ConceptsSlide() {
  const [currentSlide, setCurrentSlide] = useState(0)

  const slides = [
    {
      title: "LIVE",
      desc: "Dilengkapi beragam fasilitas-fasilitas seperti rumah sakit, sekolah bertaraf nasional, sarana komersial, taman, clubhouse, restoran dan pasar modern yang berada di dalam kawasan kota mandiri dan akan membuat kehidupan anda semakin nyaman di Grand Wisata Bekasi.",
      img: conceptLive,
    },
    {
      title: "PLAY",
      desc: "Grand Wisata Bekasi memiliki sarana rekreasi dan lifestyle premium seperti Go! Wet Water Park seluas 7,5 hektar dan mall Living World Grand Wisata yang ditargetkan akan rampung pada tahun 2023.",
      img: conceptPlay,
    },
    {
      title: "WORK",
      desc: "Grand Wisata merupakan destinasi bisnis bagi perusahaan multinasional maupun entrepreneur yang ingin mengembangkan usahanya di Grand Wisata. Grand Wisata menyediakan fasilitas komersial seperti ruko, business park, kawasan multiguna hingga kavling komersial yang dapat mengakomodasi beragam kebutuhan bisnis di Grand Wisata.",
      img: conceptWork,
    },
  ]

  const nextSlide = function () {
    setCurrentSlide(currentSlide + 1)

    if (currentSlide === slides.length - 1) {
      setCurrentSlide(0)
    }
  }

  const prevSlide = function () {
    if (currentSlide === 0) {
      setCurrentSlide(slides.length - 1)
      return
    }

    setCurrentSlide(currentSlide - 1)
  }

  return (
    <div className="row mt-5 justify-content-center">
      <div className={`col-sm-12 col-lg-9 d-flex concepts`}>
        <div className="concept-details">
          <div className="concept-text">
            <h3>{slides[currentSlide].title}</h3>
            <p>{slides[currentSlide].desc}</p>
          </div>
          <div className="concept-buttons">
            <button
              className="button-prev"
              onClick={() => prevSlide()}
              aria-label="Tombol Sebelum"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <button
              className="button-next"
              onClick={() => nextSlide()}
              aria-label="Tombol Berikut"
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        </div>
        <div className="concept-img">
          <AnimatePresence>
            <motion.img
              key={slides[currentSlide].img}
              src={slides[currentSlide].img}
              alt={slides[currentSlide].title}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                type: "Tween",
                stiffness: 300,
              }}
            ></motion.img>
          </AnimatePresence>
        </div>
      </div>
    </div>
  )
}

export default ConceptsSlide
