import { Link } from "gatsby"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import "./card.scss"
import Img from "gatsby-image"

function Card({ data }) {
  return (
    <div className="col-md-3 col-6 mb-md-4">
      <Link className="card h-100 " to={data.link}>
        <Img
          className="card-img-top"
          fluid={data.img.childImageSharp.fluid}
          alt={data.product}
        />
        <div className="card-body">
          <p>{data.type}</p>
          <h2 className="card-title">{data.product}</h2>
          <button className="card-btn" aria-label="Tombol Buka">
            <FontAwesomeIcon icon={faArrowRight} className="icon" />
          </button>
        </div>
      </Link>
    </div>
  )
}

export default Card
