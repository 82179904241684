import React, { useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import "./carousel.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import imgCarousel1 from "./../../images/carousel/carousel-1.png"
import imgCarousel2 from "./../../images/carousel/carousel-2.png"
import imgCarousel3 from "./../../images/carousel/carousel-3.png"
import imgCarousel4 from "./../../images/carousel/carousel-4.png"
import imgCarousel5 from "./../../images/carousel/carousel-5.png"
import imgCarousel6 from "./../../images/carousel/carousel-6.png"
import imgCarousel7 from "./../../images/carousel/carousel-7.png"
import imgCarousel8 from "./../../images/carousel/carousel-8.png"
import imgCarousel9 from "./../../images/carousel/carousel-9.png"
import imgCarousel10 from "./../../images/carousel/carousel-10.png"
import imgCarousel11 from "./../../images/carousel/carousel-11.png"
import imgCarousel12 from "./../../images/carousel/carousel-12.png"
import { Link } from "gatsby"

function CarouselMain() {
  const [currentSlide, setCurrentSlide] = useState(0)

  const contents = [
    {
      heading: "Grand Wisata Bekasi",
      subHeading: "Welcome to",
      image: imgCarousel1,
      link: "/about",
    },
    {
      heading: "",
      subHeading: "",
      image: imgCarousel2,
      link: "",
      contain: true,
    },
    {
      heading: "",
      subHeading: "",
      image: imgCarousel3,
      link: "",
      contain: true,
    },
    {
      heading: "",
      subHeading: "",
      image: imgCarousel4,
      link: "",
      contain: true,
    },
    {
      heading: "",
      subHeading: "",
      image: imgCarousel5,
      link: "",
      contain: true,
    },
    {
      heading: "",
      subHeading: "",
      image: imgCarousel6,
      link: "",
      contain: true,
    },
    {
      heading: "",
      subHeading: "",
      image: imgCarousel7,
      link: "",
      contain: true,
    },
    {
      heading: "",
      subHeading: "",
      image: imgCarousel8,
      link: "",
      contain: true,
    },
    {
      heading: "",
      subHeading: "",
      image: imgCarousel9,
      link: "",
      contain: true,
    },
    {
      heading: "",
      subHeading: "",
      image: imgCarousel10,
      link: "",
      contain: true,
    },
    {
      heading: "",
      subHeading: "",
      image: imgCarousel11,
      link: "",
      contain: true,
    },
    {
      heading: "",
      subHeading: "",
      image: imgCarousel12,
      link: "",
      contain: true,
    },
  ]

  const nextSlide = function () {
    setCurrentSlide(currentSlide + 1)

    if (currentSlide === contents.length - 1) {
      setCurrentSlide(0)
    }
  }

  const prevSlide = function () {
    if (currentSlide === 0) {
      setCurrentSlide(contents.length - 1)
      return
    }

    setCurrentSlide(currentSlide - 1)
  }

  return (
    <header className="carousel-main">
      <AnimatePresence>
        <motion.img
          key={contents[currentSlide].image}
          src={contents[currentSlide].image}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          alt={contents[currentSlide].heading}
          style={contents[currentSlide].contain && { objectFit: "contain" }}
        />
      </AnimatePresence>
      <div className="container">
        <div className="row">
          <div
            className="section-left col-md-6"
            style={
              contents[currentSlide].heading.length <= 0
                ? {
                    backgroundColor: "transparent",
                    ":hover": {
                      backgroundColor: "transparent",
                    },
                  }
                : {}
            }
          >
            <h3>{contents[currentSlide].subHeading}</h3>
            <h1>{contents[currentSlide].heading}</h1>
            {contents[currentSlide].link && (
              <Link
                className="button-carousel"
                to={contents[currentSlide].link}
              >
                Lebih lanjut
              </Link>
            )}

            <button
              className="prev"
              onClick={() => prevSlide()}
              aria-label="Tombol Sebelum"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
          </div>
          <div className="section-right col-md-6">
            <button
              className="next"
              onClick={() => nextSlide()}
              aria-label="Tombol Berikut"
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        </div>
      </div>
    </header>
  )
}

export default CarouselMain
